import { LeftOutlined } from "@ant-design/icons";
import { Layout } from "antd";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import useHeaderSwitch from "../../hooks/useHeaderSwitch";
import { Roles } from "../../lib/roles";
import { Routes, RoutesMenuItem } from "../../routes";
import { AuthenticationService } from "../../services/authenticationService";
import NotificationsMenu from "../NotificationsMenu/NotificationsMenu";
import UserDropdownMenu from "../UserDropdownMenu/UserDropdownMenu";
import "./app-header.scss";

export interface RouteInfo {
  path: string;
  parentPath: string;
  title: string;
}

const AppHeader = (props: any) => {
  const authenticationService = new AuthenticationService();
  const routes = new Routes().getRoutes();
  const notificationNodes = routes
    .filter((x) => !!x.isNotification && !!x.subMenuItems)
    .flatMap((x) => x.subMenuItems)
    .filter((x): x is RoutesMenuItem => !!x);
  const routesMappedParent: Map<string, RoutesMenuItem> = new Map<
    string,
    RoutesMenuItem
  >();
  const location = useLocation();

  const [activeRouteInfo, setActiveRouteInfo] = useState({});
  const { isHeaderVisible } = useHeaderSwitch();

  useEffect(() => {
    setAllRoutes();
    var route = routesMappedParent.get(location.pathname);
    if (route) {
      setActiveRouteInfo(getActiveSubItem(route, location.pathname));
    }
  }, [location.pathname]);

  const getActiveSubItem = (
    parentItem: RoutesMenuItem,
    path: string
  ): RouteInfo => {
    if (!!parentItem.subMenuItems) {
      if (
        parentItem.subMenuItems.length > 0 &&
        !!parentItem.path &&
        path != parentItem.path
      ) {
        var found = parentItem.subMenuItems.find((x) => {
          return x.subMenuPath == path;
        });
        if (!!found) {
          return {
            path: found.subMenuPath ? found.subMenuPath : "",
            parentPath: !!parentItem.path ? parentItem.path : "",
            title: found.title ? found.title : "",
          };
        }
      }
    }
    return {
      path: parentItem.path ? parentItem.path : "",
      parentPath: parentItem.path ? parentItem.path : "",
      title: !!parentItem.title ? parentItem.title : "",
    };
  };

  const setAllRoutes = () => {
    routes.forEach((route) => {
      if (route.subMenuItems) {
        routesMappedParent.set(route.path, route);
        route.subMenuItems.forEach((subMenuItem) => {
          if (subMenuItem.subMenuPath) {
            routesMappedParent.set(subMenuItem.subMenuPath, route);
          }
        });
      } else {
        if (!routesMappedParent.get(route.path)) {
          routesMappedParent.set(route.path, route);
        }
      }
    });
  };

  return !!isHeaderVisible ? (
    <Layout.Header className={"top-bar"}>
      <div
        className={"header-container"}
        style={{ justifyContent: "space-between", display: "flex" }}
      >
        <div style={{ display: "flex", marginLeft: "40px", zIndex: 2 }}>
          <div style={{ display: "flex" }}>
            {!!(activeRouteInfo as RouteInfo).parentPath &&
              (activeRouteInfo as RouteInfo).parentPath !==
                window.location.pathname && (
                <Link to={(activeRouteInfo as RouteInfo).parentPath}>
                  <div className={"circle"}>
                    <LeftOutlined
                      style={{
                        fontSize: "20px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        paddingTop: "15px",
                        marginTop: "10px",
                      }}
                    />
                  </div>
                </Link>
              )}

            <h2
              style={{
                fontSize: "20px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {(activeRouteInfo as RouteInfo).title}
            </h2>
            {props.children}
          </div>
        </div>
        <div
          style={{
            float: "right",
            textAlign: "right",
            display: "flex",
            marginTop: "15px",
          }}
        >
          {!authenticationService.isInOneOfRole([Roles.CustomerService]) && (
            <NotificationsMenu
              notificationNodes={notificationNodes}
              showTitles={false}
            />
          )}
          <UserDropdownMenu isFocused={true} />
        </div>
      </div>
    </Layout.Header>
  ) : (
    <></>
  );
};

export default AppHeader;
