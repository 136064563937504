import { fromUnixTime, isBefore } from "date-fns";
import { jwtDecode } from "jwt-decode";
import {
  AuthorizationClient,
  AuthorizationRequest,
  AuthorizationResponse,
} from "../api/ToolApiClient";
import { JwtToolPayload } from "./JwtToolPayload";

export class AuthenticationService {
  public authorizationClient = new AuthorizationClient();
  private tokenKey: string = "token";

  public login(
    login: string,
    password: string,
  ): Promise<AuthorizationResponse> {
    return this.authorizationClient.authorization(
      new AuthorizationRequest({ login, password }),
    );
  }

  public logout() {
    window.localStorage.removeItem(this.tokenKey);
  }

  public isAuthenticated(): boolean {
    return this.isAccessToken() && !this.isAccessTokenExpired();
  }

  public isAccessToken(): boolean {
    return (
      window.localStorage.getItem(this.tokenKey) != null &&
      !this.isAccessTokenExpired()
    );
  }

  public isAccessTokenExpired(): boolean {
    const expirationDate = this.getExpirationDate();
    if (expirationDate == null) {
      return true;
    }
    return isBefore(expirationDate, new Date());
  }

  public getAccessToken(): string {
    return window.localStorage.getItem(this.tokenKey) || "";
  }

  public setAccessToken(token: string) {
    return window.localStorage.setItem(this.tokenKey, token);
  }

  public getExpirationDate(): Date | null {
    if (window.localStorage.getItem(this.tokenKey) == null) {
      return null;
    }

    const token = jwtDecode<JwtToolPayload>(this.getAccessToken());
    return fromUnixTime(token.exp || 0);
  }

  public getUsername(): string {
    if (!this.isAccessToken()) {
      return "";
    }
    const token = jwtDecode<JwtToolPayload>(this.getAccessToken());
    return token.unique_name;
  }

  public getUserId(): number {
    if (!this.isAccessToken()) {
      return 0;
    }
    const token = jwtDecode<JwtToolPayload>(this.getAccessToken());
    return token.nameid;
  }

  public isInOneOfRole(roles: string[]): boolean {
    if (!this.isAccessToken()) {
      return false;
    }
    const token = jwtDecode<JwtToolPayload>(this.getAccessToken());

    let isInRole: boolean = false;

    roles.forEach((role) => {
      if (token.role == role) {
        isInRole = true;
      }
    });
    return isInRole;
  }

  public isUserInRole(role: string): boolean {
    if (!this.isAccessToken()) {
      return false;
    }
    const token = jwtDecode<JwtToolPayload>(this.getAccessToken());
    return token.role == role;
  }
}
