export enum Roles {
  Admin = "Admin",
  CampaignManager = "CampaignManager",
  Listinger = "Listinger",
  ListingerDev = "ListingerDev",
  Analyst = "Analyst",
  CustomerService = "CustomerService",
  Specialist = "Specialist",
  SpecialistContact = "SpecialistContact",
  Texter = "Texter",
  Developer = "Developer",
  DeveloperPro = "DeveloperPro",
  Operator = "Operator",
}
