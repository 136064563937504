import { Layout } from "antd";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import useHeaderSwitch from "../../hooks/useHeaderSwitch";
import { Roles } from "../../lib/roles";
import {
  RoutesMenuItem,
  Routes as ToolRoutes,
  isActiveMenu,
} from "../../routes";
import { AuthenticationService } from "../../services/authenticationService";
import AppHeaderSideBar from "../AppHeaderSideBar/AppHeaderSideBar";
import NotificationsMenu from "../NotificationsMenu/NotificationsMenu";
import UserDropdownMenu from "../UserDropdownMenu/UserDropdownMenu";
import "./side-menu.scss";
const { Sider } = Layout;

const SideMenu = () => {
  const authenticationService = new AuthenticationService();
  const location = useLocation();
  const routes = new ToolRoutes().getRoutes();
  const notificationNodes = routes
    .filter((x) => !!x.isNotification && !!x.subMenuItems)
    .flatMap((x) => x.subMenuItems)
    .filter((x): x is RoutesMenuItem => !!x);

  const [isFocused, setIsFocused] = useState(false);
  const { isHeaderVisible } = useHeaderSwitch();

  const newMenuRoutes = routes.filter((route) => {
    return route.showInMenu && !route.routeOnly && !route.isNotification;
  });

  useEffect(() => {
    setIsFocused(false);
  }, [location]);

  const handleMouseEnter = () => {
    setIsFocused(true);
  };

  const handleMouseLeave = () => {
    setIsFocused(false);
  };

  return (
    <>
      <div
        style={{
          height: "100%",
          width: "80px",
          zIndex: 3,
        }}
        onMouseLeave={handleMouseLeave}
      >
        <div
          style={{
            position: "absolute",
            top: 55,
            left: 0,
            width: "50px",
            height: "100%",
            zIndex: isFocused ? -1 : 2,
          }}
          onMouseEnter={handleMouseEnter}
        />
        <Sider
          style={{
            position: "inherit",
            height: "100%",
            overflowY: isFocused ? "auto" : "hidden",
            overflowX: "hidden",
          }}
          collapsed={!isFocused}
        >
          <AppHeaderSideBar />
          <div
            className="menu-section-container"
            onMouseLeave={handleMouseLeave}
          >
            <div className="menu-section">
              {newMenuRoutes.map((route, index) => {
                return (
                  <div key={index}>
                    {
                      <Link
                        to={route.path}
                        className={`${
                          isActiveMenu(route) ? "active" : ""
                        } generic-menu-item`}
                      >
                        <div className="icon-container">
                          <route.icon
                            style={{
                              fontSize: "20px",
                            }}
                          />
                        </div>
                        {isFocused && (
                          <span className="d-inline">{route.title}</span>
                        )}
                      </Link>
                    }
                  </div>
                );
              })}
            </div>
            {!isHeaderVisible && (
              <div className="menu-section" style={{ paddingTop: "40px" }}>
                {!authenticationService.isInOneOfRole([
                  Roles.CustomerService,
                ]) && (
                  <NotificationsMenu
                    notificationNodes={notificationNodes}
                    showTitles={isFocused}
                  />
                )}
                <UserDropdownMenu isFocused={isFocused} />
              </div>
            )}
          </div>
        </Sider>
      </div>
    </>
  );
};

export default SideMenu;
