import { ReactNode } from "react";
import {
  QueryClient,
  QueryClientProvider as _ReactQueryClientProvider,
} from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { toast } from "react-toastify";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 1000 * 60,
      refetchOnWindowFocus: false,
    },
    mutations: {
      onError: () => {
        toast.error("Something went wrong");
      },
    },
  },
});

export function QueryClientProvider({ children }: { children: ReactNode }) {
  return (
    <_ReactQueryClientProvider client={queryClient}>
      {children}
      <ReactQueryDevtools initialIsOpen={false} />
    </_ReactQueryClientProvider>
  );
}
