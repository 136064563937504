import { AxiosError } from "axios";
import { ApiException } from "./ToolApiClient";

export class ClientBase {
  protected transformOptions(options: any) {
    let token = localStorage.getItem("token");

    if (token) {
      options.headers["Authorization"] = `Bearer ${token}`;
    }
    return Promise.resolve(options);
  }

  protected transformResult(
    url: string,
    response: Response,
    processor: (response: Response) => any
  ) {
    return processor(response);
  }

  protected getBaseUrl(baseUrl: string) {
    if (process.env.NODE_ENV === "development") return "http://localhost:14067";
    return window.location.origin;
  }

  public throwException(
    message: string,
    status: number,
    response: string,
    headers: { [key: string]: any },
    result?: any
  ): any {
    if (result !== null && result !== undefined) throw result;
    else throw new ApiException(message, status, response, headers, null);
  }

  public isAxiosError(obj: any | undefined): obj is AxiosError {
    return obj && obj.isAxiosError === true;
  }
}
